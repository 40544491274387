import gql from 'graphql-tag';
import { address, orderPrice, product } from '@/core/gql/fragments';

export const plantOrders = gql`
  query orders($plant: [IdInput!], $skip: Int, $limit: Int, $status: [OrderStatusEnum!], $from: String, $to: String) {
    orders(skip: $skip, limit: $limit, plant: $plant, status: $status, from: $from, to: $to, sortOrder: asc) {
      count
      values {
        id
        address {
          ...address
        }
        contractor {
          owner {
            info {
              name
            }
            role
          }
        }
        deliveryTime
        deliveryDetails
        timezone
        product {
          ...product
        }
        prices {
          ...orderPrice
        }
        quantity
        timeBetweenTrucks
        totalPrice
        trucks
        status(detailed: true)
        currency
      }
    }
  }
  ${address}
  ${product}
  ${orderPrice}
`;
