import { CommonAddressInput, IdInput, OrderPreparatoryConfigProductTypePrice } from '@/interfaces/brokrete';
import { Apollo } from '@/core/gql/apollo';
import { GQL } from '@/core/gql';

export interface OrderPreparatoryConfigInput {
  address?: CommonAddressInput;
  category: IdInput;
  plant?: IdInput;
}

export type OrderPreparatoryConfigType = {
  contractor?: IdInput;
  currency?: String;
  input: OrderPreparatoryConfigInput;
};

export type OrderPreparatoryConfigPayload = {
  orderPreparatoryConfig: {
    productTypes: OrderPreparatoryConfigProductTypePrice[];
  };
};

const fetchProductTypes = async (variables: OrderPreparatoryConfigType): Promise<OrderPreparatoryConfigPayload | null> => {
  const result = await Apollo.client.query<OrderPreparatoryConfigPayload, OrderPreparatoryConfigType>({
    query: GQL.orderPreparatoryConfig.queries.productTypes,
    variables: variables
  });

  if (result && result.data) {
    return result.data;
  }

  return null;
};

export { fetchProductTypes };
