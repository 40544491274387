import { FC } from 'react';
import { TranslateProvider } from '@cranium/i18n';
import locales from '@/assets/locales';
import get from 'lodash/get';

function getTranslation(lang?: string) {
  return locales['en'];
}

export const TranslationsProvider: FC = ({ children }) => {
  return (
    <TranslateProvider
      storage={localStorage}
      langKey="lang"
      translationsKey="translations"
      getTranslation={getTranslation}
      defaultLanguage="en"
      monoLanguageJSON
      getLanguages={() => Array.from(new Set((process.env.REACT_APP_LANGUAGES || 'en').split(',')))}
      defaultTranslations={get(locales, window.navigator.language, get(locales, 'en'))}
    >
      {children}
    </TranslateProvider>
  );
};
