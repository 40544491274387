const colorRegExp = /^#([0-9ABCDEF]+)$/i;

class Color {
  private r: number;
  private g: number;
  private b: number;
  private a = 255;

  constructor(value: string) {
    const matches = colorRegExp.exec(value);

    if (matches == null) {
      throw new Error(`Incorrect color value: ${value}`);
    }

    const color = parseInt(matches[1], 16);

    //this.a = (color >> 24) & 0xFF;
    this.r = (color >> 16) & 0xff;
    this.g = (color >> 8) & 0xff;
    this.b = color & 0xff;
  }

  withOpacity(value: number): string {
    this.a = Math.min(255, Math.round(255 * value));
    return this.print();
  }

  private printComponent(value: number) {
    const result = value.toString(16);
    if (result.length < 2) {
      return `0${result}`;
    }
    return result;
  }

  private print() {
    return `#${this.printComponent(this.r)}${this.printComponent(this.g)}${this.printComponent(this.b)}${this.printComponent(
      this.a
    )}`.toUpperCase();
  }
}

const color = (value: string) => new Color(value);

export default color;
