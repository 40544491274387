import { createAction } from '@/rdx/libs/action';
import { ThunkDispatch } from 'redux-thunk';
import { push } from 'connected-react-router';

import localStorage from '@/core/LocalStorage';
import { User } from '@/interfaces/brokrete';
import { hideModal } from '@components/modals/hideModal';

export const LogoutActionTypes = {
  LOGOUT: 'authorization.logout'
};

export const logout = () => createAction(LogoutActionTypes.LOGOUT);

export const signOut = (user?: User | null, saveLocation?: boolean) => {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    localStorage.clear();
    if (saveLocation) {
      localStorage.setItem('location.beforeLogin', window.location.pathname);
    }

    hideModal();

    await dispatch(logout());
    await dispatch(push(`/login/${user?.type}`));
  };
};
