import {
  AvailabilityTimes,
  City,
  Contractor,
  Country,
  Order,
  OrderPreparatoryConfigFeePrice,
  OrderPreparatoryConfigInput,
  OrderPreparatoryConfigOptionPrice,
  OrderPreparatoryConfigProductDecoratePrice,
  OrderPreparatoryConfigProductTypePrice,
  OrderPreparatoryConfigTotalPrices,
  Partner,
  Place,
  Plant,
  Price,
  ProductCategory,
  TrucksConfig
} from '@/interfaces/brokrete';
import { Apollo } from '@/core/gql/apollo';
import { buildAddressInput, buildIdInput } from '@/shared/api/brokrete/helper';
import { buildOrderPreparatoryConfig } from '@/core/gql/types/order/queries';

export type OrderPreparatoryConfigType = {
  contractor?: Contractor;
  input: OrderPreparatoryConfigInput;
  order?: Order;
  partner?: Partner;
} & { productTypePrice?: Price };

export type OrderPreparatoryConfigPayload = {
  orderPreparatoryConfig: {
    availabilityTimes?: AvailabilityTimes;
    cities?: City[];
    countries?: Country[];
    fees?: OrderPreparatoryConfigFeePrice[];
    taxes?: OrderPreparatoryConfigFeePrice[];
    options?: OrderPreparatoryConfigOptionPrice[];
    plants?: Plant[];
    productDecorates?: OrderPreparatoryConfigProductDecoratePrice[];
    productTypes?: OrderPreparatoryConfigProductTypePrice[];
    products?: ProductCategory;
    trucksConfig?: TrucksConfig;
    trucksConfigs?: TrucksConfig[];
    productTypePrice?: Price;
    totalPrice?: OrderPreparatoryConfigTotalPrices;
  };
};

const fetchOrderPreparatoryConfig = async ({
  contractor,
  input,
  partner
}: OrderPreparatoryConfigType): Promise<OrderPreparatoryConfigPayload | null> => {
  // FIXME: Refacyoring is needed. Probably Lodash has some methods to remove nulls from objects
  const result = await Apollo.client.query<OrderPreparatoryConfigPayload, OrderPreparatoryConfigType>({
    query: buildOrderPreparatoryConfig({ decorates: false }),
    // @ts-ignore
    variables: {
      ...(partner && {
        currency: partner.account.currency
      }),
      ...(contractor && {
        contractor: buildIdInput(contractor)
      }),
      ...(input && {
        input: {
          ...(input.address && {
            address: buildAddressInput(input.address as Place)
          }),
          ...(input.options && {
            options: input.options.map(buildIdInput)
          }),
          ...(input.category && {
            category: buildIdInput(input.category)
          }),
          ...(input.deliveryTime && {
            deliveryTime: input.deliveryTime
          }),
          ...(input.fees && {
            fees: input.fees.map(buildIdInput)
          }),
          ...(input.plant && {
            plant: buildIdInput(input.plant)
          }),
          ...(input.trucks && {
            trucks: input.trucks
          }),
          ...(input.productPrices && {
            productPrices: input.productPrices.map(productPrice => {
              return {
                ...(productPrice.decorate && {
                  decorate: buildIdInput(productPrice.decorate)
                }),
                ...(productPrice.decoratePrice && {
                  decoratePrice: buildIdInput(productPrice.decoratePrice)
                }),
                ...(productPrice.decoratePriceValue && {
                  decoratePriceValue: productPrice.decoratePriceValue
                }),
                ...(productPrice.quantity && {
                  quantity: productPrice.quantity
                }),
                ...(productPrice.type && {
                  type: buildIdInput(productPrice.type)
                }),
                ...(productPrice.typePrice && {
                  typePrice: buildIdInput(productPrice.typePrice)
                }),
                ...(productPrice.typePriceValue && {
                  typePriceValue: productPrice.typePriceValue
                })
              };
            })
          })
        }
      })
    }
  });

  return result.data;
};

export { fetchOrderPreparatoryConfig };
