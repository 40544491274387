import 'react-widgets/dist/css/react-widgets';
import Application from '@/Application';
import * as core from '@/core';
import configureStore from '@/rdx';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import React from 'react';
import 'react-virtualized/styles.css';
import ReactDOM from 'react-dom';
import momentLocalizer from 'react-widgets-moment';
import { PersistGate } from 'redux-persist/integration/react';
import './assets/styles/antd.less';
import { PermissionsProvider } from 'shared/access';
// @ts-ignore
import HttpsRedirect from 'react-https-redirect';
import ErrorBoundary from '@/ErrorBoundary';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { TranslationsProvider } from '@/shared/i18n/TranslationsProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { get } from 'lodash';

const history = createBrowserHistory();

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      //add Router integration
      new BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(history) })
    ],
    //setup environment develop|staging|prod based on branch name from CI/CD
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.2,
    //use Commit SHA as release version
    release: process.env.SOURCE_VERSION,
    //Configuration for Redux
    normalizeDepth: 10,
    //Configuration for Apollo
    beforeBreadcrumb: breadcrumb => {
      if (breadcrumb.category === 'fetch') {
        const url: string = breadcrumb.data?.url ?? '';
        if (url.includes('/gql')) {
          return null;
        }
      }
      return breadcrumb;
    },
    ignoreErrors: [/ResizeObserver loop/, /aborted/],
    beforeSend(event) {
      // Ignore ResizeObserver loop limit exceeded error
      if (get(event, 'exception.values[0].value', '').includes('ResizeObserver loop')) {
        return null;
      }
      if (get(event, 'exception.values[0].value', '').includes('The user aborted a request')) {
        return null;
      }

      return event;
    }
  });
}

moment.locale('en');
moment.updateLocale('en', { week: { dow: 1 } });
momentLocalizer();

type RootProps = {
  client: ApolloClient<any>;
};

const Root: React.FC<RootProps> = props => {
  const { client } = props;

  const appStore = configureStore(undefined, history);

  return (
    <ErrorBoundary>
      <HttpsRedirect>
        <ApolloProvider client={client}>
          <PersistGate loading={null} persistor={appStore.persistor}>
            <TranslationsProvider>
              <Router history={history}>
                <PermissionsProvider>
                  <Application />
                </PermissionsProvider>
              </Router>
            </TranslationsProvider>
          </PersistGate>
        </ApolloProvider>
      </HttpsRedirect>
    </ErrorBoundary>
  );
};

const initializeApplication = async () => {
  const client = await core.apolloClient;

  ReactDOM.render(<Root client={client} />, document.getElementById('root'));
};

initializeApplication();
