import gql from 'graphql-tag';

export const account = gql`
  fragment account on ContractorAccount {
    id
    balance
    credit
    creditExpiredAt
  }
`;

export const supplier = gql`
  fragment supplier on Supplier {
    id
    name
  }
`;

export const country = gql`
  fragment country on Country {
    id
    name
    code
    currency
  }
`;

export const address = gql`
  fragment address on Address {
    id
    location {
      latitude
      longitude
    }
    display
    city
    country
    state
    zip
    custom
    mainText
    streetAddress
  }
`;

export const city = gql`
  fragment city on City {
    id
    name
    address {
      location {
        latitude
        longitude
      }
      display
      city
      country
    }
    country {
      name
      currency
    }
    countryState {
      code
    }
  }
`;

export const orderImage = gql`
  fragment orderImage on OrderImage {
    id
    type
    image {
      id
      source
    }
    createdAt
  }
`;

export const projectImage = gql`
  fragment projectImage on ProjectImage {
    id
    image {
      id
      source
    }
    createdAt
  }
`;

export const card = gql`
  fragment card on PaymentMethodCard {
    id
    uid
    brand
    last4
    expMonth
    expYear
    country
  }
`;

export const product = gql`
  fragment product on ProductCategory {
    id
    name
    image
    industry
    permissions {
      decorates
      deliveryFees
      fullEdit
      options
      productColors
      productModifications
      seasonalFees
      trucks
      underloadFees
      washoutFees
    }
  }
`;

export const productTypeOptionValue = gql`
  fragment productTypeOptionValue on ProductTypeOptionValue {
    description
    id
    value
  }
`;

export const productTypeOptions = gql`
  fragment productTypeOptions on ProductTypeOptions {
    label
    values {
      ...productTypeOptionValue
    }
  }
  ${productTypeOptionValue}
`;

export const productType = gql`
  fragment productType on ProductType {
    id
    name
    category {
      ...product
    }
    parent {
      id
      name
      image
    }
    modifications {
      name
      color
      image
    }
    group
    image
    description
    specification
    options {
      ...productTypeOptions
    }
    optionCombinations {
      id
      description
      value
    }
    possibleOptionsValues {
      id
      value
      description
    }
  }
  ${product}
  ${productTypeOptions}
`;

export const productDecorate = gql`
  fragment productDecorate on ProductDecorate {
    id
    name
    color
    image
    category {
      id
      name
      industry
    }
  }
`;

export const price = gql`
  fragment price on Price {
    id
    value
    formula {
      id
    }
    content
    currency
  }
`;

export const skid = gql`
  fragment skid on Skid {
    id
    name
  }
`;

export const fee = gql`
  fragment fee on Fee {
    id
    name
    type
    details {
      from
      to
      multiplier
    }
    zeroOut
  }
`;

export const option = gql`
  fragment option on Option {
    id
    name
    customized
    driverOption
  }
`;

export const orderPrice = gql`
  fragment orderPrice on OrderPrice {
    id
    fee {
      ...fee
    }
    option {
      ...option
    }
    productDecorate {
      ...productDecorate
    }
    productType {
      ...productType
    }
    price {
      ...price
    }
    skid {
      ...skid
    }
    quantity
    value
    anchorPrice {
      id
    }
  }
  ${fee}
  ${option}
  ${productDecorate}
  ${productType}
  ${price}
  ${skid}
`;

export const driver = gql`
  fragment driver on Driver {
    id
    location {
      latitude
      longitude
    }
  }
`;

export const orderTruck = gql`
  fragment orderTruck on OrderTruck {
    driver {
      ...driver
    }
    deliveryTime
    quantity
    status
  }
  ${driver}
`;

export const order = gql`
  fragment order on Order {
    id
    prettyId
    paymentMode
    priceMode
    additional
    address {
      ...address
    }
    contractor {
      id
      exportCode
      companyInfo {
        name
      }
      owner {
        id
        info {
          name
          avatar
        }
      }
    }
    deliveryTime
    lockToUpdateTime
    lockToCancelTime
    deliveryDetails
    timezone
    images {
      ...orderImage
    }
    prices {
      ...orderPrice
    }
    product {
      ...product
    }
    quantity
    timeBetweenTrucks
    totalPrice
    trucks
    trucksConfig {
      quantity {
        max
        min
        step
      }
      count {
        max
      }
    }
    status(detailed: true)
    paymentMethod {
      provider
      card {
        ...card
      }
    }
    holdLimits
    pouringTime
    hasToBeRated
    currency
    project {
      id
      name
    }
    plant {
      id
      name
    }
  }
  ${address}
  ${fee}
  ${option}
  ${orderPrice}
  ${product}
  ${productDecorate}
  ${productType}
  ${card}
  ${orderImage}
`;

export const plant = gql`
  fragment plant on Plant {
    id
    name
    timezone
    color
    deliveryRadius
    deliveryRadiusUnit
    productDecorates {
      count
      values {
        active
        productDecorate {
          ...productDecorate
        }
      }
    }
    productTypes {
      count
      groups
      values {
        active
        productType {
          ...productType
        }
      }
    }
    productTypePrices {
      id
      price {
        id
        priority
        value
        content
        currency
      }
      productType {
        id
        name
        active
        category {
          id
          name
        }
        options {
          label
          values {
            id
            description
            value
          }
        }
      }
    }
    productCategories {
      id
      image
      name
      industry
    }
    supplier {
      ...supplier
    }
    address {
      ...address
    }
    city {
      ...city
    }
    options {
      count
      values {
        active
        option {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
    deliveryRadius
    deliveryMethods {
      category {
        id
      }
      deliveryMethod {
        id
        name
        description
        caption
      }
    }
  }
  ${address}
  ${productDecorate}
  ${productType}
  ${supplier}
  ${city}
`;

export const project = gql`
  fragment project on Project {
    id
    name
    type
    spent
    address {
      ...address
    }
    orders {
      id
    }
    images {
      ...projectImage
    }
  }
  ${address}
  ${projectImage}
`;

export const identity = gql`
  fragment identity on Identity {
    uid
    provider
    confirmed
  }
`;

export const member = gql`
  fragment member on ContractorMember {
    id
    info {
      name
      avatar
    }
    role
    identities {
      ...identity
    }
  }
  ${identity}
`;

export const contractor = gql`
  fragment contractor on Contractor {
    id
    info {
      type
      additional
      country {
        id
        name
        code
        currency
      }
    }
    companyInfo {
      name
      address
      logo
    }
    orders {
      ...order
    }
    owner {
      id
      info {
        name
        avatar
      }
    }
    testMode
  }
  ${order}
`;

export const contractorMember = gql`
  fragment contractorMember on ContractorMember {
    ...member
    contractor {
      id
      exportCode
      info {
        type
        country {
          id
          name
          code
          currency
        }
      }
      companyInfo {
        name
        address
        logo
      }
      owner {
        ...member
      }
    }
    other {
      ...member
      contractor {
        id
        owner {
          ...member
        }
        companyInfo {
          name
          logo
        }
      }
    }
  }
  ${identity}
  ${member}
`;

export const partner = gql`
  fragment partner on Partner {
    id
    code
    email
    name
    logo
  }
`;

export const superAdmin = gql`
  fragment superAdmin on SuperAdmin {
    id
    email
  }
`;
export const partnerContractor = gql`
  fragment partnerContractor on PartnerContractor {
    id
    source
    status
    statusChangedAt
    priceStatus
    priceDetails {
      groups
      personal
    }
  }
`;

export const transaction = gql`
  fragment transaction on Transaction {
    id
    provider
    amount
    currency
    type
    status
    createdAt
  }
`;

export const contractorPaymentMethod = gql`
  fragment contractorPaymentMethod on PaymentMethod {
    account {
      allowCreditCard
      allowOffline
      balance
      credit
      creditExpiredAt
      id
      name
      offlineBanks {
        accountHolderName
        accountNumber
        bankNumber
        routingNumber
      }
      partner {
        id
        name
      }
      type
    }
    card {
      brand
      country
      expMonth
      expYear
      id
      last4
      provider
      uid
      cardholderName
    }
    id
    provider
  }
`;

export const contractorPaymentMethodShort = gql`
  fragment contractorPaymentMethodShort on PaymentMethod {
    account {
      balance
      credit
      id
      name
      type
    }
    card {
      brand
      country
      expMonth
      expYear
      cardholderName
      id
      last4
      provider
      uid
    }
    id
    provider
  }
`;

export const paymentIntent = gql`
  fragment paymentIntent on PaymentIntent {
    active
    availableActions
    amount
    createdAt
    currency
    id
    mode
    paymentLink {
      amount
      processingAmount
    }
    status
    statusChangedAt
    updatedAt
    processingAmount
    paymentTransaction {
      id
      amount
      currency
      status
      createdAt
      description
      offlineType
      paymentMethod {
        account {
          name
          balance
          credit
        }
        provider
        card {
          brand
          last4
        }
      }
      prettyId
      provider
      reason {
        type
      }
      type
      payoutAmount
    }
    prettyId
  }
`;

export const paymentLink = gql`
  fragment paymentLink on PaymentLink {
    id
    prettyId
    amount
    currency
    link
    status
    createdAt
    updatedAt
    contractor {
      displayName
    }
    paymentTransaction {
      id
      processingAmount
      paymentMethod {
        id
        account {
          name
        }
        card {
          id
          brand
          last4
        }
        provider
      }
    }
    items {
      id
      status
      amount
      order {
        id
        prettyId
      }
      currency
    }
  }
`;
