import React, { Component } from 'react';
import ReactTagsInput, { RenderTagProps } from 'react-tagsinput';

import 'react-tagsinput/react-tagsinput.css';
import Tag from './Tag';

type Props = {
  tags: any[];
  className?: string;
  tagRenderer?: (props: RenderTagProps) => React.ReactNode;
  tagDisplayProp?: string;
  tagComponent?: React.ElementType;
  onChange: (tags: any[], changed: any[], changedIndexes: number[]) => void;
};

type State = {};

class TagsInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  onEditTag = (key: number, tag: any) => {
    const tags = [...this.props.tags];
    tags[key] = tag;

    this.props.onChange(tags, [tag], [key]);
  };

  renderTag = (props: RenderTagProps) => {
    const { tagComponent: TagComponent = Tag } = this.props;

    return (
      <TagComponent
        key={props.key}
        item={props.tag}
        tagIndex={props.key}
        onRemove={() => props.onRemove(props.key)}
        onChange={this.onEditTag}
      />
    );
  };

  render() {
    const { tags, className, tagDisplayProp, onChange } = this.props;

    const inputProps = {
      placeholder: 'Type here and press "Enter"'
    };

    return (
      <div>
        <ReactTagsInput
          className={className}
          value={tags}
          onChange={onChange}
          inputProps={inputProps}
          renderTag={this.renderTag}
          addOnBlur
          tagDisplayProp={tagDisplayProp}
          //@ts-ignore
          validate={tag => tag.trim() !== ''} //to prevent add empty tag
        />
      </div>
    );
  }
}

export default TagsInput;
