import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
const Login = React.lazy(() => import('@pages/Login').then(module => ({ default: module.Login })));
import AdminRouter from './AdminRouter';
import { PrivateRoute } from '@/shared/access';
export default function ApplicationRouter() {
  return (
    <Switch>
      <Route path="/login" exact component={() => <Redirect to={'/login/partner'} />} key="/login" />
      <Route
        path="/login/:identity?"
        component={(props: any) => (
          <Suspense fallback={null}>
            <Login {...props} />
          </Suspense>
        )}
        key="/login"
      />
      <PrivateRoute path="/" component={AdminRouter} />
    </Switch>
  );
}
