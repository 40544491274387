import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useLocation } from 'react-router-dom';
import { useUIContext } from '@components/context/ApplicationContext';
import { NavLink } from '@/shared/navigation';
import { isEmpty } from '@/libs';

const StyledNavLink = styled(NavLink)<{ active: boolean; minimized: boolean }>`
  min-height: 40px;
  width: 100%;
  padding: ${props => (props.minimized ? '0' : '0 12px')};
  display: grid;
  grid-template-columns: 24px auto min-content;
  align-items: center;
  border-left: ${props => (props.active && !props.minimized ? '3px solid var(--green-6)' : 'none')};
  padding-left: ${props => (props.minimized ? '0' : props.active ? '9px' : '12px')};
  text-decoration: none !important;
  background-color: ${props => (props.active && !props.minimized ? 'var(--green-2)' : 'transparent')};
  border-radius: ${props => (props.minimized ? '4px' : '0 8px 8px 0')};
  user-select: none;

  &:hover {
    background-color: ${props => (props.minimized ? 'transparent' : 'var(--green-2)')};
  }

  &:hover > span {
    color: ${props => (props.minimized ? 'var(--green-6)' : 'default')} !important;
  }

  span {
    color: ${props => (props.active ? 'var(--green-6)' : 'var(--gray-7)')} !important;
    justify-self: ${props => (props.minimized ? 'center' : 'start')};
  }
`;

const Icon = styled.span`
  font-size: 16px;
  transform: translateY(-2px);
`;

const Counter = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: end;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #fff;
  background-color: var(--green-6);
  cursor: default;
`;

interface Props {
  iconClass: string;
  label: string;
  path: string;
  href?: string;
  exact?: boolean;
  count?: number;
}

export const NavLinkItem: React.FunctionComponent<Props> = ({ iconClass, label, path, href, exact, count }) => {
  const location = useLocation();
  const {
    sidebar: { minimized }
  } = useUIContext();

  const isActivePath = (path: string, href: string | undefined) => {
    if (href) return false;
    if (path === '/drivers' && location.pathname.includes('schedule')) {
      return false;
    }
    return location.pathname.includes(path);
  };

  const linkPath = href ? { pathname: href } : path;

  return (
    <StyledNavLink
      to={linkPath}
      active={isActivePath(path, href)}
      minimized={minimized}
      exact={exact}
      target={href ? '_blank' : '_self'}
      shouldParseLocation={isEmpty(href)}
    >
      <Icon className={iconClass} />
      {!minimized && (
        <>
          <Typography.Text className="normal-14">{label}</Typography.Text>
          {!!count && <Counter>{count}</Counter>}
        </>
      )}
    </StyledNavLink>
  );
};
