import Logger, { log } from './Logger';
import configuration from './Configuration';
import configureApollo from './gql';
import type { I18nKeys } from '../assets/locales';
import geolocation from './geolocation';
import { gettext } from '@cranium/i18n';

import localStorage from './LocalStorage';

export const apolloClient = configureApollo(configuration, localStorage);

export const i18n = {
  t: (key: I18nKeys, params?: any) => gettext(`i18n.${key}`, params)
};

export { Logger, log, localStorage, configuration };

export const core = {
  geolocation
};
