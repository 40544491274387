import React from 'react';
import styled from 'styled-components';
import { CollapsibleMenuItem } from '@components/layout/Sidebar/components/CollapsibleMenuItem';
import ProtectedRenderer from '@components/ProtectedRenderer';
import { MenuItem, useSidebarStructure } from '@components/layout/Sidebar/useSidebarStructure';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 12px;
  overflow: hidden auto;
`;

export const Sidebar = () => {
  const menuStructure = useSidebarStructure();

  const renderMenuItem = (item: MenuItem, index: number) => {
    if (!item.allowed) return null;

    if (item.withProtectedRenderer) {
      return (
        <ProtectedRenderer key={index} allowedUserTypes={item.withProtectedRenderer}>
          {item.component}
        </ProtectedRenderer>
      );
    }

    return <React.Fragment key={index}>{item.component}</React.Fragment>;
  };

  return (
    <Container>
      {menuStructure.map((entry, index) => {
        if (entry.type === 'single') {
          return renderMenuItem(entry.item, index);
        } else if (entry.type === 'grouped') {
          const paths = entry.items.map(item => item.name);
          const notEmpty = entry.items.some(item => item.allowed);
          if (notEmpty) {
            return (
              <CollapsibleMenuItem title={entry.title} openByDefault={entry.openByDefault} paths={paths} key={index}>
                {entry.items.map((item, itemIndex) => renderMenuItem(item, itemIndex))}
              </CollapsibleMenuItem>
            );
          }
        }
      })}
    </Container>
  );
};
