import gql from 'graphql-tag';

export const orders = gql`
  query orders(
    $skip: Int
    $limit: Int
    $status: [OrderStatusEnum!]
    $plant: [IdInput!]
    $supplier: [IdInput!]
    $updatedAtFrom: String
  ) {
    orders(skip: $skip, limit: $limit, status: $status, supplier: $supplier, plant: $plant, updatedAtFrom: $updatedAtFrom) {
      count
      values {
        id
        contractor {
          id
          info {
            name
          }
          companyInfo {
            name
          }
        }
        address {
          display
        }
        city {
          id
          name
          country {
            name
          }
        }
        product {
          id
          name
        }
        plant {
          id
          name
        }
        quantity
        totalPrice
        currency
        status(detailed: true)
        trucks
        timeBetweenTrucks
        deliveryTime
        timezone
      }
    }
  }
`;
