import { ILocalStorage } from '../interfaces/application';

class LocalStorage implements ILocalStorage {
  private isAvailable: boolean | undefined = undefined;

  get isStorageAvailable() {
    if (this.isAvailable === undefined) {
      try {
        const x = '__storage_test__';

        localStorage.setItem(x, x);
        localStorage.removeItem(x);

        this.isAvailable = true;
      } catch (e) {
        this.isAvailable = false;
      }
    }

    return this.isAvailable;
  }

  setItem(key: string, value: any): void {
    if (this.isStorageAvailable) {
      localStorage.setItem(key, value);
    }
  }

  getItem(key: string): any {
    if (this.isStorageAvailable) {
      return localStorage.getItem(key);
    }

    return null;
  }

  clear() {
    if (this.isStorageAvailable) {
      localStorage.clear();
    }
  }
}

export default new LocalStorage();
