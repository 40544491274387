export enum Platform {
  androidStorefront = 'android_storefront',
  iosStorefront = 'ios_storefront',
  webSite = 'web_site',
  webWidget = 'web_widget',
  webDashboard = 'web_dashboard',
  webStorefront = 'web_storefront',
  admin = 'admin'
}

export enum PaymentSystem {
  eway = 'eway'
}
