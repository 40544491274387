import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Space, Spin, Typography } from 'antd';
import { colors } from '@/defaultStyles';
import ICONS from '@/assets/images/icons';
import { DeleteFilled, ReloadOutlined } from '@ant-design/icons';
import { OrderFeePrice, useOrderFee } from '@components/OrderFeePanel/useOrderFee';
import { OrderPreparatoryConfigInput } from '@/interfaces/brokrete';
import { isEqual, noop, omit } from 'lodash';
import { useOrderFeePanel } from '@components/OrderFeePanel/useOrderFeePanel';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useGettext } from '@cranium/i18n';
import { useRequestIdentity } from '@/view/hooks/useRequestIdentity';
import { OrderFeePanelInstance } from './types';
import { useGetClientSettings, useHasPermissions } from '@/shared/access';

const Container = styled.div<{ enable: boolean }>`
  padding: 23px 0 0;
  .visibility {
    display: ${props => (props.enable ? 'grid' : 'none')};
  }
`;

const ListContainer = styled.div`
  display: grid;
  text-align: initial;
  grid-template-rows: auto auto 15px;
  box-shadow: 0 2px 8px rgba(74, 82, 104, 0.12);
  border-radius: 4px;

  .header {
    padding: 12px 12px 7px;
    border-bottom: 1px solid rgba(74, 82, 104, 0.12);
    color: ${colors.BATTLESHIP_GREY};
  }

  .item {
    height: 32px;
    padding: 0 12px;
    display: grid;
    grid-template-columns: 1fr auto 12px;
    grid-column-gap: 12px;
    align-items: center;
    justify-content: space-between;

    &:nth-of-type(even) {
      background-color: ${colors.LIGHT};
    }

    &[data-status='new'] {
      color: ${colors.GREEN};
      .ant-typography {
        color: ${colors.GREEN};
      }
    }

    &[data-status='old'] {
      color: ${colors.LIGHT_PERIWINKLE};
      text-decoration: line-through;
      .ant-typography {
        color: ${colors.LIGHT_PERIWINKLE};
      }
    }
  }

  .footer {
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: rgba(80, 196, 104, 0.1);
    cursor: pointer;

    &[aria-expanded='true'] {
      > img {
        transform: rotate(180deg);
      }
    }
  }
`;

enum Statuses {
  old = 'old',
  changed = 'changed',
  new = 'new'
}

export interface CompositionOrderFeePanel<P> extends React.FC<P> {
  TotalPrice: typeof TotalPrice;
  useInstance: typeof useOrderFeePanel;
}

interface Props {
  orderId: string;
  instance: OrderFeePanelInstance;
  input: OrderPreparatoryConfigInput;
  forceRecalculate?: boolean;
  onLoading: (loading: boolean) => void;
}

export const OrderFeePanel: CompositionOrderFeePanel<Props> = ({
  orderId,
  input,
  instance,
  forceRecalculate = false,
  onLoading
}) => {
  const canShowPrices = useHasPermissions('permissions.orders.price.show');
  const { gettext } = useGettext();
  const { isValid, getUniqueId } = useRequestIdentity();
  const [confirmationModalOpened, setConfirmationModalOpened] = useState<boolean>(false);
  const { fetch, fetchTotalPrice, loading } = useOrderFee(orderId);
  const [checked, setChecked] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [fees, setFees] = useState<OrderFeePrice[]>([]);
  const [changed, setChanged] = useState(false);
  const inputRef = useRef(input);
  const autoRecalculate = useGetClientSettings('orders.fees.auto_recalculate');

  useEffect(() => {
    if (!forceRecalculate) {
      setChecked(autoRecalculate);
    }
  }, [forceRecalculate, autoRecalculate]);

  const fetchFees = useCallback(
    async (input: OrderPreparatoryConfigInput) => {
      const requestId = getUniqueId();

      const { feePrices } = await fetch(input);

      if (isValid(requestId)) {
        setFees(feePrices);
      }
    },
    [fetch]
  );

  useMemo(() => setChanged(!isEqual(input, inputRef.current)), [input]);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  const refreshFees = useCallback(() => {
    void fetchFees(input);
    inputRef.current = input;
    setChanged(false);
  }, [input, inputRef, fetchFees]);

  useEffect(() => {
    if (changed && checked) {
      const timeout = setTimeout(() => refreshFees(), 200);

      return () => clearTimeout(timeout);
    }
  }, [changed, checked, refreshFees]);

  const onCheckboxChange = useCallback(
    (value: boolean) => {
      if (value === checked) return;
      if (value) void fetchFees(input);
      setChecked(value);
      inputRef.current = input;
      setChanged(false);
    },
    [checked, input]
  );

  useMemo(() => {
    if (instance) {
      Object.assign(instance, {
        requireActionConfirmation: () => {
          return checked && changed;
        },
        confirmAction: async () => {
          setConfirmationModalOpened(true);
        },
        getCurrentPrice: () => {
          const inputData = {
            ...input,
            fees: fees
              .filter(v => v.status !== Statuses.old)
              .map(({ fee, price }) => ({ id: fee.id, price: { id: price.id as string } }))
          };

          return fetchTotalPrice(checked ? inputData : input).then(data => data && { value: data?.value });
        },
        getFeePrices: (): Omit<OrderFeePrice, 'status'>[] | undefined => {
          if (!checked) return undefined;
          return fees
            .filter(v => v.status !== Statuses.old)
            .map(feePrice => {
              return omit(feePrice, 'status');
            });
        },
        getFee: () => {
          if (!checked) return undefined;
          return fees
            .filter(v => v.status !== Statuses.old)
            .map(({ fee, price }) => ({
              id: fee.id,
              price: { id: price.id }
            }));
        },
        setChecked: onCheckboxChange
      });
    }
  }, [instance, fees, checked, changed, input]);

  return (
    <Container enable={checked}>
      <Spin spinning={loading}>
        <Space direction="vertical" className="d-flex" size={14}>
          {!forceRecalculate && (
            <Checkbox checked={checked} onChange={e => onCheckboxChange(e.target.checked)}>
              {gettext('Re-Calculate Fees')}
            </Checkbox>
          )}
          <ListContainer className={'visibility'}>
            <div className="header">{gettext('Fees')}</div>
            <div>
              {expanded &&
                fees
                  .filter(v => !!v.fee && !['tax', 'processing'].includes(v.fee.type))
                  .map(item => (
                    <div key={item.fee.id} className={'item'} data-status={item.status}>
                      <Typography.Text>{item.fee.name}</Typography.Text>
                      <Typography.Text className={canShowPrices ? '' : 'regular-12'}>
                        {canShowPrices ? `$${item.value}` : '✱.✱✱'}
                      </Typography.Text>
                      {item.status === Statuses.old && (
                        <ReloadOutlined
                          style={{ color: colors.GREEN, cursor: 'pointer' }}
                          onClick={() => {
                            setFees(v => v.map(v => (v.fee.id === item.fee.id ? { ...v, status: Statuses.changed } : v)));
                          }}
                        />
                      )}
                      {item.status === Statuses.new && (
                        <DeleteFilled
                          style={{ color: 'rgba(0, 0, 0, 0.45)', cursor: 'pointer' }}
                          onClick={() => {
                            setFees(fees.filter(v => v.fee.id !== item.fee.id));
                          }}
                        />
                      )}
                    </div>
                  ))}
            </div>
            <div className="footer" aria-expanded={expanded} onClick={() => setExpanded(v => !v)}>
              <img src={ICONS.icChevronDown} alt="" />
            </div>
          </ListContainer>
          <Space />
        </Space>
      </Spin>

      <ConfirmationModal
        title={gettext('Fees needs to be recalculated')}
        text={gettext('Before continue fees will be re-calculated')}
        buttonConfirmTitle={gettext('Re-Calculate fees')}
        isOpen={confirmationModalOpened}
        onConfirm={async () => {
          await refreshFees();
          setConfirmationModalOpened(false);
        }}
      />
    </Container>
  );
};

interface TotalPriceProps {
  feePanelInstance: OrderFeePanelInstance;
  defaultValue?: number;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void | Promise<void>;
}

const TotalPrice: React.FunctionComponent<TotalPriceProps> = ({ feePanelInstance, defaultValue, onClick = noop }) => {
  const canShowPrices = useHasPermissions('permissions.orders.price.show');
  const [price, setPrice] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const { gettext } = useGettext();
  const priceValue = price === undefined ? '' : `$${price}`;

  if (!canShowPrices) {
    return <div />;
  }

  return (
    <Space>
      <span>
        <Typography.Text strong>{gettext('Total Order Price')}:</Typography.Text>
        <Typography.Text>{` ${priceValue}`}</Typography.Text>
      </span>
      <Button
        type="link"
        onClick={e => {
          setLoading(true);
          onClick(e);
          feePanelInstance.getCurrentPrice().then(value => {
            setPrice(value?.value);
            setLoading(false);
          });
        }}
        loading={loading}
        style={{ padding: 0, minWidth: 'auto', width: 'auto' }}
        icon={<ReloadOutlined style={{ color: colors.GREEN, cursor: 'pointer' }} />}
        shape="circle"
      />
    </Space>
  );
};

OrderFeePanel.TotalPrice = TotalPrice;
OrderFeePanel.useInstance = useOrderFeePanel;
