import { Region, ProductCategory, Location, Project, Plant } from '@/interfaces/brokrete';
import { Apollo } from '@/core/gql/apollo';
import { GQL } from '@/core/gql';
import { AddressInput, Contractor, IdInput } from '@/gql/types.generated';

type QueryInputType = {
  input: {
    address?: AddressInput;
    plant?: IdInput;
    project?: IdInput;
    pickup: boolean;
  };
  contractor?: Contractor;
  region: Region;
};

type ResponseQuery = {
  orderPreparatoryConfig: {
    products: ProductCategory[];
  };
};

const fetchProductCategories = async (variables: {
  contractor?: Contractor;
  address?: AddressInput;
  region?: Region;
  location?: Location;
  plant?: Plant;
  project?: Project;
  pickup?: boolean;
}): Promise<ResponseQuery | null> => {
  let region = variables.region;
  const { address, contractor, project, plant, pickup } = variables;

  if (!region && variables.location) {
    const location = new window.google.maps.LatLng(variables.location.latitude, variables.location.longitude);

    const circle = new window.google.maps.Circle({
      center: location,
      radius: 500 * 1000
    });

    const { west, east, north, south } = circle.getBounds()!.toJSON();

    region = {
      longitude: (west + east) / 2,
      latitude: (north + south) / 2,
      deltaLongitude: Math.abs(west - east),
      deltaLatitude: Math.abs(north - south)
    };
  }

  if (region) {
    const result = await Apollo.client.query<ResponseQuery, QueryInputType>({
      query: GQL.orderPreparatoryConfig.queries.productCategories,
      variables: {
        contractor,
        input: {
          address,
          plant,
          project,
          pickup: !!pickup
        },
        region
      }
    });

    if (result && result.data) {
      return result.data;
    }
  }

  return null;
};

export { fetchProductCategories };
