import icArrowDown from './ic-arrow-down.svg';
import icFactory from './ic-factory.svg';
import icCalendar from './ic-calendar.svg';
import icCalendarGrey from './ic-calendar_grey.svg';
import icSchedule from './ic-schedule.svg';
import icScheduleGrey from './ic-schedule_grey.svg';
import icTime from './ic-time.svg';
import icPencil from './ic-pencil.svg';
import icPinBig from './ic-pin-big.svg';
import icTruck from './ic-truck.svg';
import icSync from './ic-sync.svg';
import icCompactView from './ic-compact-view.svg';
import icDetailedView from './ic-detailed-view.svg';
import icSearch from './ic-search.svg';
import icCheckGreen from './ic-check-green.svg';
import icAddressCardContractor from './ic-address-card-contractor.svg';
import icPhone from './ic-phone.svg';
import icEmail from './ic-email.svg';
import icCSV from './ic-csv.svg';
import icReceipt from './ic-receipt.svg';
import icMapPin from './ic-map-pin.svg';
import icChevronDown from './ic-shevron.svg';
import icColourPicker from './ic-colour-picker.svg';
import icCheckmark from './ic-checkmark.svg';
import icTruckConcrete from './ic-truck-concrete.svg';
import icTruckContainer from './ic-truck-container.svg';
import icMenuVertical from './ic-menu-vertical.svg';
import icAdd from './ic-add.svg';
import icArchive from './ic-archive.svg';
import icDelete from './ic-delete.svg';
import icClock from './ic-clock.svg';
import icSignIn from './ic-sign-in.svg';
import icPlus from './ic-plus.svg';
import icPlusSmall from './ic-plus-small.svg';
import icBell from './ic-bell.svg';
import icMinus from './ic-minus.svg';
import icCreditCard from './ic-credit-card.svg';
import icCreditCardGreen from './ic-credit-card-green.svg';
import icExchangeCard from './ic-exchange-card.svg';
import icHandle from './ic-handle.svg';
import icGlobe from './ic-globe.svg';
import icSignOut from './ic-sign-out.svg';
import icCard from './ic-card.svg';
import icPlusGreenCircle from './ic-plus-green-circle.svg';
import icAvatar from './ic-avatar.svg';
import icChevronLeft from './ic-chevron-left.svg';
import icChevronRight from './ic-chevron-right.svg';
import icClone from './ic-clone.svg';
import icDuplicate from './ic-duplicate.svg';
import icImport from './ic-import.svg';
import icEyeOpen from './ic-eye-open.svg';
import icEyeClose from './ic-eye-close.svg';
import icList from './ic-list.svg';
import icColor from './ic-color.svg';
import icExclamationRed from './ic-exclamation-red.svg';
import icDecorate from './ic-decorate.svg';
import icMessagesGrey from './ic-messages-grey.svg';
import icMessagesGreen from './ic-messages-green.svg';
import icMessageRead from './ic-message-read.svg';
import icMessageNotRead from './ic-message-not-read.svg';
import icCloseButton from './ic-close-button.svg';
import icOption from './ic-options.svg';
import icCalendarDarkGrey from './ic-calendar-dark-grey.svg';
import icNoDrivers from './ic-no-drivers.svg';
import icSealer from './ic-sealer.svg';
import icCopy from './ic-copy.svg';
import icNoDecorates from './ic-no-decorates.svg';
import icDecoratePlaceholder from './ic-decorate-placeholder.svg';
import icAddContractor from './ic-add-contractor.svg';
import icBankBuilding from './ic-bank-building.svg';
import icArrowRight from './ic-control-arrow-right.svg';
import icDottedBuilding from './ic-dotted-building.svg';
import icPerson from './ic-person.svg';
import icLogoWhite from './ic-logo-white.svg';
import ic404 from './ic-404.svg';
import icPurse from './ic-purse.svg';
import icBrokrete from './ic-brokrete.svg';
import icVisa from './ic-visa.svg';
import icMastercard from './ic-mastercard.svg';
import icBilld from './ic-billd.svg';
import icFlex from './ic-flex.svg';
import tbdTruck from './tbd-truck.svg';
import icEway from './ic-eway.svg';
import icAmex from './ic-amex.svg';
import icDinners from './ic-dinners.svg';
import icBilldXs from './ic-billd-xs.svg';
import icVisaXs from './ic-visa-xs.svg';
import icCheckXs from './ic-check-xs.svg';
import icPaymentLinkXs from './ic-payment-link-xs.svg';
import icFlexXs from './ic-flex-xs.svg';
import icBankXs from './ic-bank-xs.svg';
import icCreditAccount from './ic-credit-account.svg';
import icOffline from './ic-offline.svg';
import icBilldTransparent from './ic-billd-transparent.svg';

const ICONS = {
  icArrowDown,
  icFactory,
  icCalendar,
  icCalendarGrey,
  icSchedule,
  icScheduleGrey,
  icTime,
  icPencil,
  icPinBig,
  icTruck,
  icSync,
  icCompactView,
  icDetailedView,
  icSearch,
  icCheckGreen,
  icAddressCardContractor,
  icPhone,
  icEmail,
  icCSV,
  icReceipt,
  icMapPin,
  icChevronDown,
  icColourPicker,
  icCheckmark,
  icTruckConcrete,
  icTruckContainer,
  icMenuVertical,
  icAdd,
  icArchive,
  icDelete,
  icClock,
  icSignIn,
  icPlus,
  icPlusSmall,
  icBell,
  icMinus,
  icCreditCard,
  icExchangeCard,
  icHandle,
  icGlobe,
  icSignOut,
  icCard,
  icPlusGreenCircle,
  icAvatar,
  icChevronLeft,
  icChevronRight,
  icClone,
  icDuplicate,
  icImport,
  icEyeOpen,
  icEyeClose,
  icList,
  icColor,
  icExclamationRed,
  icDecorate,
  icMessagesGrey,
  icMessagesGreen,
  icMessageRead,
  icMessageNotRead,
  icCloseButton,
  icOption,
  icCalendarDarkGrey,
  icSealer,
  icNoDrivers,
  icCopy,
  icNoDecorates,
  icDecoratePlaceholder,
  icAddContractor,
  icBankBuilding,
  icArrowRight,
  icDottedBuilding,
  icPerson,
  icLogoWhite,
  ic404,
  icPurse,
  tbdTruck,
  icCreditCardGreen,
  icBrokrete,
  icVisa,
  icMastercard,
  icBilld,
  icFlex,
  icEway,
  icAmex,
  icDinners,
  icBilldXs,
  icVisaXs,
  icCheckXs,
  icPaymentLinkXs,
  icFlexXs,
  icCreditAccount,
  icBankXs,
  icOffline,
  icBilldTransparent
};

export default ICONS;
