import { User } from '@/interfaces/brokrete';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

export const useMeMember = () => {
  const [member, setMember] = useState<MemberInfo | undefined>();
  const { data } = useQuery<{ me: User }>(ME_QUERY, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data) {
      const { me } = data;
      if (me.partnerMember) {
        const identity = me.partnerMember.identities.find(v => v.provider === 'email');
        identity &&
          setMember({
            id: me.partnerMember.id,
            email: identity.uid,
            name: me.partnerMember.partner.name,
            managerName: me.partnerMember.name,
            role: me.partnerMember.role,
            avatar: me.partnerMember.avatar
          });
      } else if (me.supplierMember) {
        const identity = me.supplierMember.identities.find(v => v.provider === 'email');
        identity &&
          setMember({
            email: identity.uid,
            name: me.supplierMember.supplier.name,
            role: me.supplierMember.role,
            avatar: me.supplierMember.avatar
          });
      }
    }
  }, [data, setMember]);

  return member;
};

/* gql */
const ME_QUERY = gql`
  query me {
    me {
      partnerMember {
        id
        name
        role
        avatar
        identities {
          provider
          uid
        }
        identity {
          provider
          uid
        }
        partner {
          name
        }
      }
      supplierMember {
        id
        name
        role
        avatar
        identities {
          provider
          uid
        }
        identity {
          provider
          uid
        }
        supplier {
          name
        }
      }
    }
  }
`;

/* types */
type MemberInfo = {
  id?: string;
  email?: string;
  name?: string;
  managerName?: string;
  avatar?: string;
  role?: string;
};
