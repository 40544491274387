import en from './en.json';
import customStr from './customstrings_en.json';
import gettextStr from './translations_en.json';

const locales = {
  en: {
    ...en,
    ...gettextStr,
    ...customStr
  }
};

export default locales;

export type Translations = typeof en.i18n;

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? //@ts-ignore
      `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type I18nKeys = NestedKeyOf<typeof en.i18n>;
