import { ApolloLink } from '@apollo/client';
import { ILocalStorage } from '@/interfaces/application';

export const initLangLink = (storage: ILocalStorage) => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'Accept-Language': storage.getItem('lang') || 'en'
      }
    }));

    return forward(operation);
  });
};
