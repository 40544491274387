import {
  IdInput,
  OrderPreparatoryConfigOptionPrice,
  OrderPreparatoryConfigProductDecoratePrice,
  Place,
  TrucksConfig
} from '@/interfaces/brokrete';
import { Apollo } from '@/core/gql/apollo';
import { GQL } from '@/core/gql';
import { buildAddressInput, buildIdInput } from '@/shared/api/brokrete/helper';
import { OrderPreparatoryConfigInput } from '@/core/api/orderPreparatoryConfig/productTypes';

export type OrderPreparatoryConfigType = {
  contractor: IdInput;
  currency?: string;
  input: OrderPreparatoryConfigInput;
  productTypePrice: IdInput;
};

export type OrderPreparatoryConfigPayload = {
  orderPreparatoryConfig: {
    productDecorates: OrderPreparatoryConfigProductDecoratePrice[];
    options: OrderPreparatoryConfigOptionPrice[];
    trucksConfig: TrucksConfig;
  };
};

const fetchProductDecorates = async ({
  contractor,
  currency,
  input,
  productTypePrice
}: OrderPreparatoryConfigType): Promise<OrderPreparatoryConfigPayload | null> => {
  const result = await Apollo.client.query<OrderPreparatoryConfigPayload, OrderPreparatoryConfigType>({
    query: GQL.orderPreparatoryConfig.queries.productDecorates,
    variables: {
      currency,
      contractor: buildIdInput(contractor),
      input: {
        address: buildAddressInput(input.address as Place),
        category: buildIdInput(input.category),
        ...(input.plant && {
          plant: buildIdInput(input.plant)
        })
      },
      productTypePrice: buildIdInput(productTypePrice)
    }
  });

  if (result && result.data) {
    return result.data;
  }

  return null;
};

export { fetchProductDecorates };
