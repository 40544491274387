import { CommonAddressInput, IdInput, SkidPreparatoryConfigInstance } from '@/interfaces/brokrete';
import { Apollo } from '@/core/gql/apollo';
import { GQL } from '@/core/gql';

export interface OrderPreparatoryConfigInput {
  address: CommonAddressInput;
  category: IdInput;
  productPrices: {
    typePrice: IdInput;
    quantity: number;
  }[];
}

export type OrderPreparatoryConfigType = {
  input: OrderPreparatoryConfigInput;
};

export type OrderPreparatoryConfigPayload = {
  orderPreparatoryConfig: {
    skids: SkidPreparatoryConfigInstance[];
  };
};

const fetchSkids = async (variables: OrderPreparatoryConfigType): Promise<OrderPreparatoryConfigPayload | null> => {
  const result = await Apollo.client.query<OrderPreparatoryConfigPayload, OrderPreparatoryConfigType>({
    query: GQL.orderPreparatoryConfig.queries.skids,
    variables: variables
  });

  if (result && result.data) {
    return result.data;
  }

  return null;
};

export { fetchSkids };
